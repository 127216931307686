import $ from 'jquery';
import 'bootstrap';
import 'parsleyjs';
import  Cookies from 'js-cookie';
import "magnific-popup";
import 'jquery-match-height'
import AOS from 'aos';
import Swiper from 'swiper';
import 'bootstrap-select';
import 'simplebar';
import 'tilt.js';
import NiceSelect from 'nice-select2/dist/js/nice-select2';
import Parallax from 'parallax-js';
import TweenLite from "gsap/TweenLite";
import 'superfish';

var jQueryBridget = require('jquery-bridget');
var Isotope = require('isotope-layout');
jQueryBridget( 'isotope', Isotope, $ );
var imagesloaded = require('imagesloaded');
jQueryBridget( 'imagesLoaded', imagesloaded, $ );

window.$ = window.jQuery = $;

let Page = Page || {};
(function($) {

    /* ---------------------------------------------
        Global Variables
    --------------------------------------------- */
    var $window = $(window),
        $html = $('html'),
        $body = $('body');
    
    /* ---------------------------------------------
        APP document Ready
    --------------------------------------------- */
    Page.documentOnReady = {
        init: function() {
            Page.initialize.init();
        }
    };
    
    /* ---------------------------------------------
        Page document Load
    --------------------------------------------- */
    Page.documentOnLoad = {
        init: function() {
            Page.initialize.preloader();
        }
    };
    
    /* ---------------------------------------------
        APP document Resize
    --------------------------------------------- */
    Page.documentOnResize = {
        init: function() {
            var t = setTimeout(function() {
                //
            }, 250);
        }
    };
    
    /* ---------------------------------------------
        Scripts initialization
    --------------------------------------------- */
    $(document).ready( Page.documentOnReady.init);
    $window.on('load', Page.documentOnLoad.init);
    $window.on('resize', Page.documentOnResize.init);
    
    /* ------------------------------------------------------------------------------------------------------------------
        #############################################################################################################
    ------------------------------------------------------------------------------------------------------------------ */

    Page.initialize = {
        init: function() {
            Page.initialize.aos();
            Page.initialize.cookie();
            Page.initialize.header();
            Page.initialize.innerLinks();
            Page.initialize.swiper();
            Page.initialize.tilt();
            Page.initialize.magnificPopup();
            Page.initialize.calc();
            Page.initialize.customFile();
            Page.initialize.hero();
            Page.initialize.niceselect();
            Page.initialize.superfish();
        },

        superfish: function() {
            $('.header ul.header-menu .sub-menu ul').css('display', 'block');
            $('.header ul.header-menu .sub-menu ul').css('display', '');
            $('.offcanvas .header-menu .sub-menu ul').css('display', 'block');
            $('.offcanvas .header-menu .sub-menu ul').css('display', '');
            if (!$().superfish) {
                $body.addClass('no-superfish');
                console.log('superfish: Superfish not Defined.');
                return true;
            }
            $('.header ul.header-menu').superfish({
                popUpSelector: 'ul',
                delay: 250,
                speed: 350,
                animation: {opacity: 'show'},
                animationOut: {opacity: 'hide'},
                cssArrows: false
            });
            $('.offcanvas .header-menu').superfish({
                popUpSelector: 'ul',
                delay: 250,
                speed: 350,
                animation: {opacity: 'show'},
                animationOut: {opacity: 'hide'},
                cssArrows: false
            });
        },

        niceselect: function() {
            $("select").each(function() {
                new NiceSelect(this);
            });
        },

        hero: function(){
            $('.svg-layers svg .active').on('mouseenter', function () {
                var $this = $(this),
                    $target = $("#" + $this.data("target"));

                var $box = $this[0].getBoundingClientRect();
                $target.addClass("show");
                $target.css({left: $this.position().left + ($box.width/2), top: $box.top});
            }).on('mouseleave', function () {
                var $this = $(this),
                    $target = $("#" + $this.data("target"));

                $target.removeClass("show");
            }).on('click', function () {
                var $this = $(this),
                    $group = $this.parent();

                window.location.href = $group.data("redirect");
            });
        },
        customFile: function(){

            var bHaveFileAPI = (window.File && window.FileReader);

            if ( bHaveFileAPI ){

                $( '.custom-file' ).each( function() {
                    var $fileHolder=  $(this);
                    var $input = $(this).find('input[type=file]');

                    $input.on( 'change', function( evt ) {
                        var file = evt.target.files[0];

                        $fileHolder.find(".custom-file-control").html(file.name);
                    });
                    $input
                        .on( 'focus', function(){ $input.addClass( 'has-focus' ); })
                        .on( 'blur', function(){ $input.removeClass( 'has-focus' ); });
                });
            }
        },
        /* ---------------------------------------------
                    magnificPopup
                --------------------------------------------- */
        calc: function() {
            let $target = $(".container-no-right-margin");
            if ($target.length) {
                update($target);
                $(window).on('resize', function () {
                    update($target);
                });

                function update($target) {
                    if($(window).width() > 992) {
                        var width = $(".container").outerWidth() + $(".container .row").position().left - 40;
                        $target.css("width", width);
                    }
                    else {
                        $target.css("width", '');
                    }
                }
            }
        },

        /* ---------------------------------------------
                    magnificPopup
                --------------------------------------------- */
        magnificPopup: function() {
            let $mfpGallery = $('.mfp-gallery');
            if ($mfpGallery.length) {
                $mfpGallery.each(function() { // the containers for all your galleries
                    $(this).magnificPopup({
                        delegate: 'a:not(.disable-mfp)', // the selector for gallery item
                        type: 'image',
                        //mainClass: 'mfp-with-zoom', // this class is for CSS animation below
                        mainClass: 'mfp-zoom-in',
                        showCloseBtn: true,
                        closeBtnInside: true,
                        closeOnContentClick: true,
                        closeMarkup: '<span class="mfp-close"><span class="icon icon-cross-out1"></span></span>',
                        gallery: {
                            enabled: true,
                            arrowMarkup: '<span title="%title%" class="mfp-arrow mfp-arrow-%dir%"></span>', // markup of an arrow button
                        },
                        image: {
                            // titleSrc: function(item) {
                            //     return item.el.find('.overlay-content').html();
                            // }
                        },
                        removalDelay: 300, //delay removal by X to allow out-animation
                        callbacks: {
                            open: function() {
                                //overwrite default prev + next function. Add timeout for css3 crossfade animation
                                $.magnificPopup.instance.next = function() {
                                    var self = this;
                                    self.wrap.removeClass('mfp-image-loaded');
                                    setTimeout(function() { $.magnificPopup.proto.next.call(self); }, 120);
                                }
                                $.magnificPopup.instance.prev = function() {
                                    var self = this;
                                    self.wrap.removeClass('mfp-image-loaded');
                                    setTimeout(function() { $.magnificPopup.proto.prev.call(self); }, 120);
                                }
                            },
                            imageLoadComplete: function() {
                                var self = this;
                                setTimeout(function() { self.wrap.addClass('mfp-image-loaded'); }, 16);
                            }
                        }
                    });
                });
            };

            $('.popup-video').magnificPopup({
                disableOn: 700,
                type: 'iframe',
                mainClass: 'mfp-fade',
                removalDelay: 160,
                preloader: false,
                fixedContentPos: false
            });

            $('[data-lightbox="inline"]').magnificPopup({
                type:'inline',
            });
        },

        /* ---------------------------------------------
            Tilt
        --------------------------------------------- */
        tilt: function(){

            if ( $(window).width() > 1199 ){

                $('.js-tilt-hover').tilt({
                    glare: true,
                    maxTilt: 8,
                    maxGlare: .2,
                });

                $('.js-tilt-hover-sm').tilt({
                    glare: false,
                    maxTilt: 2,
                });
            }
        },
        /* ---------------------------------------------
            Preloader
        --------------------------------------------- */
        preloader: function() {
            $body.removeClass("loading");
            $body.addClass("loaded");
        },
        
        /* ---------------------------------------------
            swiper
        --------------------------------------------- */
        swiper: function(){
            if ($('#swiper-main').length > 0) {
                new Swiper('#swiper-main', {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    loop: true,
                    speed: 2500,
                    // effect: 'fade',
                    /*fadeEffect: {
                        crossFade: true
                    },*/
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                });
            }
            if ( $('.swiper-gallery').length > 0 ) {
                new Swiper('.swiper-gallery', {
                    // effect: 'fade',
                    slidesPerView: 6,
                    spaceBetween: 0,
                    simulateTouch: true,
                    loop: true,
                    slideToClickedSlide: true,
                    allowTouchMove: true,
                    uniqueNavElements: false,
                    preventInteractionOnTransition: true,
                    centeredSlides: true,
                    speed: 1200,
                    autoplay: {
                        delay: 1500,
                        disableOnInteraction: false,
                    },
                    breakpoints: {
                        // when window width is <= 640px
                        576: {
                            slidesPerView: 2,
                        },
                        768: {
                            slidesPerView: 3,
                        },
                        1440: {
                            slidesPerView: 5,
                        },
                    }
                });
            }
            if ( $('.swiper-partners').length > 0 ) {
                new Swiper('.swiper-partners', {
                    // effect: 'fade',
                    slidesPerView: 6,
                    spaceBetween: 30,
                    simulateTouch: true,
                    loop: true,
                    slideToClickedSlide: true,
                    allowTouchMove: true,
                    uniqueNavElements: false,
                    preventInteractionOnTransition: true,
                    // centeredSlides: true,
                    speed: 1200,
                    autoplay: {
                        delay: 1500,
                        disableOnInteraction: false,
                    },
                    breakpoints: {
                        // when window width is <= 640px
                        576: {
                            slidesPerView: 2,
                        },
                        768: {
                            slidesPerView: 3,
                        },
                        1440: {
                            slidesPerView: 5,
                        },
                    }
                });
            }
            if ( $('.swiper-offer').length > 0 ) {
                new Swiper('.swiper-offer', {
                    // effect: 'fade',
                    slidesPerView: 3,
                    spaceBetween: 60,
                    simulateTouch: true,
                    loop: true,
                    slideToClickedSlide: true,
                    allowTouchMove: true,
                    uniqueNavElements: false,
                    preventInteractionOnTransition: true,
                    speed: 1200,
                    centeredSlides: true,
                    navigation: {
                        nextEl: $('.offer-next'),
                        prevEl: $('.offer-prev'),
                    },
                    breakpoints: {
                        // when window width is <= 640px
                        576: {
                            spaceBetween: 100,
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        1440: {
                            slidesPerView: 3,
                        },
                    }
                });
            }
            if ( $('.swiper-blog').length > 0 ) {
                new Swiper('.swiper-blog', {
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true
                    },
                    // effect: 'fade',
                    slidesPerView: 1,
                    spaceBetween: 0,
                    simulateTouch: true,
                    slideToClickedSlide: true,
                    allowTouchMove: true,
                    uniqueNavElements: false,
                    preventInteractionOnTransition: true,
                    speed: 1200,
                    navigation: {
                        nextEl: $('.blog-next'),
                        prevEl: $('.blog-prev'),
                    },
                });
            }
            if ( $('.swiper-why').length > 0 ) {
                let whySwiper = new Swiper('.swiper-why', {
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true
                    },
                    // effect: 'fade',
                    slidesPerView: 1,
                    spaceBetween: 0,
                    speed: 1200,
                });
                whySwiper.on('slideChangeTransitionStart', function () {
                    $(".why-navigation ul li a").removeClass("active");
                    $(".why-navigation ul li a[data-slide=" + whySwiper.activeIndex + "]").addClass("active");
                });
                $(".why-navigation ul li a").on("click", function () {
                    var $this = $(this);
                    $(".why-navigation ul li a").removeClass("active");
                    $this.addClass("active");
                    whySwiper.slideTo($this.data("slide"));
                    $('html, body').animate({
                        scrollTop: $(".section-why").offset().top - $(".header").outerHeight()
                    }, 600);
                });
            }
        },

        /* ---------------------------------------------
            Cookie
        --------------------------------------------- */
        cookie: function () {
            let $cookieDiv = $("#cookie");
            let cookie_delay = 3000;
            if ( !Cookies.get('cookie-notyfication') ) {
                setTimeout(function () {
                    $cookieDiv.addClass("show");
                }, cookie_delay);
                $('#cookie-close').on('click', function () {
                    Cookies.set('cookie-notyfication', 'accept', { expires: 365 });
                    $cookieDiv.removeClass("show");
                });
            } 
        },
        
        /* ---------------------------------------------
            Aos
        --------------------------------------------- */
        aos: function() {
            AOS.init({
                // Global settings
                //disable: $(window).width() < 1199, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
                //startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
                initClassName: 'aos-init', // class applied after initialization
                animatedClassName: 'aos-animate', // class applied on animation
                useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
              
                // Settings that can be overriden on per-element basis, by `data-aos-*` attributes:
                offset: 150, // offset (in px) from the original trigger point
                delay: 400, // values from 0 to 3000, with step 50ms
                duration: 450, // values from 0 to 3000, with step 50ms
                easing: 'ease', // default easing for AOS animations
                once: true, // whether animation should happen only once - while scrolling down
                mirror: false, // whether elements should animate out while scrolling past them
                anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
            });

            window.addEventListener('load', AOS.refresh);
        },

        /* ---------------------------------------------
            Inner links
        --------------------------------------------- */
        innerLinks: function(){
            if($('.inner-link').length){
                $('.inner-link').on('click', function (e) {
                    e.preventDefault();
                    var href = $(this).attr('href');
                    var speed = parseInt( $(this).attr('data-speed'), 10) || 600;
                    var offset = $(this).attr('data-offset') || 108;
                    $('html, body').animate({
                        scrollTop: $(href).offset().top - offset
                    }, speed);
                    return false;
                });
            }
        },

        /* ---------------------------------------------
            Header, main menu
        --------------------------------------------- */
        header: function () {
            let $offcanvasMenu= $('#offcanvas');
            let $toggleMenu = $(".toggle-menu");
            let scroll_amount = 1;

            
            //menu
            $toggleMenu.click(function(){
                $("#toggle-menu").toggleClass("is-active");
                $offcanvasMenu.toggleClass('active');
                $('body').toggleClass("menu-is-active");
            });
            //menu
            $("#offcanvas .has-megamenu").click(function(){
                $(this).toggleClass('active');
            });

            if ( $window.scrollTop() > scroll_amount ){
                $body.addClass("page-scroll");
            }

            $window.scroll(function() {
                if ( $(this).scrollTop() > scroll_amount ){
                    $body.addClass("page-scroll");
                } else{
                    $body.removeClass("page-scroll");
                }
            });
        },
    };
})(jQuery);





